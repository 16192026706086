import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-hls-quality-selector";
import "videojs-contrib-quality-levels";

// https://docs.videojs.com/tutorial-react.html
export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady, sas, className, videoJson } = props;

  // Add Azure Blob SAS to HLS lists
  // https://github.com/videojs/http-streaming#vhsxhr
  videojs.Vhs.xhr.beforeRequest = function (o) {
    if (sas) {
      o.uri = o.uri + sas;
    }
    return o;
  };

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        onReady && onReady(player);
      }));
      player.hlsQualitySelector({ displayCurrentQuality: true });
      player.playbackRates([0.5, 1, 1.2, 1.3, 1.5, 2]);
    } else {
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className={className}>
      <div data-vjs-player>
        <video
          data-matomo-title={videoJson.title}
          ref={videoRef}
          className="video-js vjs-big-play-centered"
        />
      </div>
    </div>
  );
};

export default VideoJS;
