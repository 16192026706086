import React from "react";
import VideoJS from "./video";
import { cdn } from "../../authConfig";
import { useLocation } from "@reach/router";

const FreeVideo = (props) => {
  const { videoJson, className, withDoc } = props;
  const location = useLocation();
  const cdnUrl =
    location.hostname === "www.mizuno-ch.cn" ? cdn.url_cn : cdn.url;

  // https://docs.videojs.com/tutorial-react.html
  const playerRef = React.useRef(null);
  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    //playbackRates: [0.5, 1, 1.5, 2],
    responsive: true,
    fluid: true,
    sources: [
      {
        src: cdnUrl + `/public/videos/${videoJson.slug}/playlist.m3u8`,
        type: "application/x-mpegURL",
      },
    ],
    poster: cdnUrl + `/public/thumbnails/${videoJson.slug}.webp`,
  };
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    <>
      <VideoJS
        options={videoJsOptions}
        onReady={handlePlayerReady}
        className={className}
        videoJson={videoJson}
      />
      {withDoc &&
      videoJson.series !== "katari" &&
      videoJson.series !== "taidan" ? (
        <div className="flex justify-center">
          <a
            href={cdnUrl + `/public/docs/${videoJson.slug}.pdf`}
            download
            target="_blank"
            rel="noreferrer"
            className="inline-block px-5 md:px-10 py-2 md:py-3 bg-cyan-800 hover:bg-cyan-700 text-white rounded-md mt-5 text-center font-sans tracking-wider"
          >
            レジュメPDFダウンロード
          </a>
        </div>
      ) : null}
    </>
  );
};

export default FreeVideo;
